/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  function configurer(){

    var scrollPosition = $(window).scrollTop();

    if(scrollPosition > 0){
      $('#main-header').addClass('scrolled');
    }


      $('.front-slider').slick({
        fade: true,
        arrows: false,
        dots: true,
        autoplay: true,
      });

      $('.image-slider').slick({
        fade: true,
        arrows: false,
        dots: true,
      });

      $('.temoignage-slider').slick({
        autoplay: true,
        arrows: false,
        dots: true,
      });

      $('.slider-nouvelles').slick({
        autoplay: false,
        arrows: true,
        dots: false,
        infinite: false,
      });

      $('.slider-videos').slick({
        autoplay: false,
        arrows: true,
        dots: false,
        infinite: false,
      });

      $('.realisations-slider').slick({
        autoplay: false,
        arrows: true,
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
        {
          breakpoint: 650,
          settings: {
            slidesToShow: 1,
          }
        },
      ]
      });

      //Anchor animations
      $('a[href^="#"]').on('click touchend', function() {
        var the_id = $(this).attr("href");
        if(the_id !== '#') {

          if($(window).width() > 992){            

            $('html, body').animate({
              scrollTop: $(the_id).offset().top-150
            }, 'slow');

          }else {        

            $('html, body').animate({
              scrollTop: $(the_id).offset().top-50
            }, 'slow');
          }

        }
        return false;

      });

      //Accordeon - Open/Close
      $('.accordeon .accordeon-title').on('click', function() {
        
        var current = $(this);

        current.parent().toggleClass('open');

        if(current.parent().hasClass('open')){

          current.parent().find(".accordeon-content-wrapper").slideDown();

          //delay - 500ms
          setTimeout(function() {
            current.parent().find('.accordeon-content').addClass('visible');
          }, 500);
        }else {
          current.parent().find('.accordeon-content').removeClass('visible');

          //delay - 500ms
          setTimeout(function() {
            current.parent().find(".accordeon-content-wrapper").slideUp();
          }, 500);
        }   

      });

      //Accordeon (sub) - Open/Close
      $('.accordeon-child .accordeon-child-title').on('click', function() {
        
        var current = $(this);

        current.parent().toggleClass('open');

        if(current.parent().hasClass('open')){

          current.parent().find(".accordeon-child-content-wrapper").slideDown();

          //delay - 500ms
          setTimeout(function() {
            current.parent().find('.accordeon-child-content').addClass('visible');
          }, 500);
        }else {
          current.parent().find('.accordeon-child-content').removeClass('visible');

          //delay - 500ms
          setTimeout(function() {
            current.parent().find(".accordeon-child-content-wrapper").slideUp();
          }, 500);
        }   

      });

      $('.menu-trigger').on('click', function() {
        $('#mobile-nav').toggleClass('visible');
        $(this).toggleClass('active');
      });


      $(window).on('resize', function() {

        /*$('.accordeon.open').each(function() {
          $(this).removeClass('open');
          $(this).find('.accordeon-content-wrapper').slideUp();
          
        });*/


      });


      $('#front-videos-slider .play').on('click', function(e) {
       
       var vimeoId = $(this).attr('data-vimeo-id');
       var videoId = $(this).attr('data-video-id');
       
        openVideoModal(e, vimeoId, videoId);

        $('body').addClass('no-scroll');

      }); 

      $('.close-modal').click(function() {

        $('#modal-video').removeClass('visible');
        $("#modal-video .videoWrapper").slideUp();

        $('#modal-video .videoWrapper').each(function() {
            var iframe = $(this).find('iframe');
            
            if(iframe){

              var player = new Vimeo.Player(iframe);

              if(player){
               player.pause();
             }          

             } 

        });

        $('body').removeClass('no-scroll');
        
      });

    
      var maxWidth = 0;

      $('.accordeon').each(function(){

        var width = $(this).find('.accordeon-title').outerWidth()+15;

        if(width > maxWidth){
          maxWidth = width;
        }
      });

      $('.accordeon').each(function(){
       
        $(this).find('.accordeon-title').css('width', maxWidth);
        $(this).find('.accordeon-content-wrapper').slideUp();

      });


      setTimeout(function() {
        //Transition profils détudes fixed
        jQuery(function($) {

          var $win = $(window);
          var viewportHeight = $win.height();
          var winH = ($win.height()/3);   // Get the window height.
          
          var footerPos = $('#site-footer').position().top;


          $win.on("scroll", function () {

            var scrollNoVh = $(window).scrollTop();

            if(scrollNoVh == 0){
              $('#main-header').removeClass('scrolled');
            }else{
              $('#main-header').addClass('scrolled');
            }

            var scroll = $(window).scrollTop()+viewportHeight;

              if ($(this).scrollTop() > winH) {
                  if(scroll > footerPos){
                    $('#selecteur-profils').removeClass('visible');
                  }else {
                    $('#selecteur-profils').addClass('visible');
                  }
                  
              } else {
                  $('#selecteur-profils').removeClass('visible');
              }
          }).on("resize", function(){ // If the user resizes the window
             winH = $(this).height(); // you'll need the new height value
          });

      });
      }, 1000);


      //Radio wrap
      $('.wpcf7-radio .wpcf7-list-item').wrap("<label class='radio-container'></label>");
      $('.wpcf7-radio .wpcf7-list-item').append('<span class="checkmark"></span>');


      //See more
      $('.readmore-link').on('click', function() {

        $(this).parent().find('.content-readmore').slideToggle();

        var currentText = $(this).text();

        if(currentText == "Voir moins"){
          $(this).text('Voir plus');
        }else {
          $(this).text('Voir moins');
        }        

      });

      if(window.location.hash) {
        // Fragment exists
        var anchor = window.location.hash;

        if(anchor !== "#content") {

          anchor = anchor.replace('#','');

          if($('#'+anchor).hasClass('anchor')){
            $('#'+anchor).parent().find('.accordeon').addClass('open');
            $('#'+anchor).parent().find('.accordeon-content-wrapper').slideDown();
            $('#'+anchor).parent().find('.accordeon-content').addClass('visible');      

            setTimeout(function() {              

              $('html, body').animate({
                scrollTop:  $('#'+anchor).offset().top
               }, 'slow');
              
            }, 500);
            

          }else {
            $('#'+anchor).find('.accordeon').addClass('open');
            $('#'+anchor).find('.accordeon-content-wrapper').slideDown();
            $('#'+anchor).find('.accordeon-content').addClass('visible');
          }

        
          

        }

      }

      setTimeout(function() {
        $('body').addClass('loaded');
        $('.loader').addClass('loaded');
      }, 500);
      
      
  }

  function openVideoModal(e, vimeoId, videoId){

      $('#modal-video').addClass('visible');

      $("#modal-video .videoWrapper[data-id='"+videoId+"']").slideDown();

      var iframe = $("#modal-video .videoWrapper[data-id='"+videoId+"'] iframe");

      var player = new Vimeo.Player(iframe);  

      player.play();


  }


  window.onload = configurer;

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
